<template>
  <div>
    <v-radio-group v-model="radioValue" @change="setFieldValue()" row>
      <v-radio
        v-for="(option, index) in params.options"
        :key="index"
        :label="option.text"
        :value="option.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: ["params", "index", "index_actions", "rules_list"],
  data() {
    return {
      radioValue: ""
    };
  },
  mounted() {
    this.radioValue = this.rules_list[this.index].actions[
      this.index_actions
    ].fields[this.params.name][this.params.name];
  },

  computed: {
    ...mapState("rules", ["rules"])
  },

  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    setFieldValue() {
      this.rules_list[this.index].actions[this.index_actions].fields[
        this.params.name
      ][this.params.name] = this.radioValue;
      this.setRules(this.rules_list);
      this.$emit("changeShowStatus", {
        component: this.params.name,
        status: this.radioValue
      });
    }
  }
};
</script>
<style scoped></style>
