<template>
  <v-col>
    <v-autocomplete
      :label="label"
      :items="tags"
      item-text="tags_at_format"
      item-value="tag"
      v-model="tagValue"
      @change="updateTagList()"
    ></v-autocomplete>
  </v-col>
</template>

<script>
export default {
  props: ["label", "tags"],
  data() {
    return {
      tagValue: null
    };
  },

  methods: {
    updateTagList() {
      this.$emit("changeTags", this.tagValue);
      this.tagValue = null;
    }
  }
};
</script>
<style scoped></style>
