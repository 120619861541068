<template>
  <v-row class="mx-auto">
    <div v-if="loading">
      <v-col>
        <v-skeleton-loader
          v-bind="attrs"
          type="article, actions"
        ></v-skeleton-loader>
      </v-col>
    </div>
    <v-col md="4" sm="6" v-for="plan in plans.data" :key="plan.id">
      <v-card class="mx-auto">
        <v-card-title class="primary white--text" :title="plan.name">
          <div class="headerClass">
            {{ plan.name }}
          </div>
        </v-card-title>
        <v-card-subtitle align="right" class="primary white--text"
          ><h3>{{ plan.price_format }}</h3></v-card-subtitle
        >
        <v-card-text>
          <v-row align="center">
            <v-col class="text-center">
              <v-icon x-large>mdi-web</v-icon>
              <div class="title">{{ plan.data }}</div>
              <div class="subtitle-2">Dados</div>
            </v-col>
            <v-col class="text-center">
              <v-icon x-large>mdi-phone-outgoing</v-icon>
              <div class="title">{{ plan.voice }}</div>
              <div class="subtitle-2">Chamadas</div>
            </v-col>
            <v-col class="text-center">
              <v-icon x-large>mdi-message-text-outline</v-icon>
              <div class="title">{{ plan.sms }}</div>
              <div class="subtitle-2">Mensagens</div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="plan.amount"
                @input="updateAmount()"
                type="number"
                outlined
                dense
                label="Qtde. Linhas"
                :rules="linhasRules"
              />
            </v-col>
            <v-col cols="12" align="center" v-if="plan.description">
              <v-btn text @click="showInfo(plan.id)" color="primary" small>
                Mais informações
                <v-icon>{{
                  show === plan.id ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-expand-transition>
          <div v-show="show === plan.id">
            <v-divider></v-divider>

            <v-card-text>
              <div v-html="plan.description"></div>
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      loading: true,
      show: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },
      linhasRules: [
        v =>
          (v && v >= 0) || "A quantidade de linhas não pode ser menor que zero"
      ]
    };
  },
  computed: {
    ...mapState("plans", ["plans"]),
    ...mapGetters({
      name: "forms/getItemName"
    })
  },
  methods: {
    ...mapActions("plans", ["loadPlans"]),
    ...mapMutations({
      setModel: "forms/setModelPlans"
    }),
    updateAmount() {
      this.setModel(this.plans);
    },
    showInfo(plan_id) {
      this.show = this.show.length ? false : plan_id;
    }
  },
  async mounted() {
    await this.loadPlans().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
