<template>
  <div>
    <v-text-field
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show ? 'text' : 'password'"
      v-model="passValue"
      :label="params.label"
      outlined
      dense
      :placeholder="params.label"
      @click:append="show = !show"
      @keyup="setFieldValue(passValue)"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: ["params", "index", "index_actions", "rules_list"],
  data() {
    return {
      passValue: "",
      show: false
    };
  },

  mounted() {
    this.passValue = this.rules_list[this.index].actions[
      this.index_actions
    ].fields[this.params.name][this.params.name];
  },

  computed: {
    ...mapState("rules", ["rules"])
  },

  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    setFieldValue(passValue) {
      this.rules_list[this.index].actions[this.index_actions].fields[
        this.params.name
      ][this.params.name] = passValue;
      this.setRules(this.rules_list);
    }
  }
};
</script>
<style scoped></style>
