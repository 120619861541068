<template>
  <v-row justify="center">
    <v-dialog v-model="showComponent" persistent max-width="600px">
      <template v-if="showIcon" v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" @click="listTags(tagType)"
          >mdi-tag-plus
        </v-icon>
      </template>
      <v-card>
        <v-card-title v-if="tagType === 'subjectField'">
          <span class="text-h5">Adicionar Tags ao Assunto</span>
        </v-card-title>
        <v-card-title v-if="tagType === 'bodyField'">
          <span class="text-h5">Adicionar Tags</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <ModuleTagField
                :tags="allTags"
                :label="field.label"
                @changeTags="changeTags"
              />
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="hiddenModal">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { ModuleTagField } from "@/components/fields";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  props: ["tagType", "showIcon", "modalStatus", "selectedId"],
  components: { ModuleTagField },
  watch: {
    modalStatus() {
      this.showComponent = true;
    }
  },
  data() {
    return {
      dialog: false,
      showSimpleContent: true,
      showComponent: false,
      allTags: [],
      field: {
        label: "Tags"
      }
    };
  },
  async mounted() {
    if (this.bodyTags?.length < 1 || this.tagList?.length < 1) {
      await this.loadTagList({
        id: this.selectedId
      }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });
    }
    this.createTagList(this.tagType);
    this.showComponent = this.modalStatus;
  },
  computed: {
    ...mapState("tags", ["bodyTags", "tagList"]),
    ...mapState("solicitationType", ["fields", "new"]),
    ...mapState("rules", ["tag_list"]),
  },

  methods: {
    ...mapActions("tags", ["loadTags"]),
    ...mapActions("rules", ["loadTagList"]),

    createTagList() {
      this.allTags = this.listTags();
      return this.allTags;
    },

    listTags() {
      this.allTags = [];
      this.tag_list.data.map(field => {
        this.allTags.push({ tag: field.name, tags_at_format: field.label });
      });
      return this.allTags;
    },

    changeTags(tags) {
      if (this.tagType === "bodyField") {
        this.$emit("addTagEmail", tags);
      }
      if (this.tagType === "subjectField") {
        this.$emit("addTagSubject", tags);
      }
      this.showComponent = false;
    },

    hiddenModal() {
      this.showComponent = false;
    }
  }
};
</script>
