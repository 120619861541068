<template>
  <div>
    <v-text-field
      v-model="value"
      :label="label"
      outlined
      dense
      @input="setValueText"
      autofocus
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      value: ""
    };
  },
  computed: {
    ...mapGetters({
      label: "forms/getItemLabel",
      getItemText: "forms/getItemText"
    })
  },
  methods: {
    ...mapMutations({
      setModel: "forms/setModelText"
    }),
    setValueText() {
      this.setModel({ value: this.value, text: this.value });
    }
  },
  created() {
    this.value = this.getItemText;
  },
  updated() {
    this.value = this.getItemText;
  }
};
</script>
<style scoped></style>
