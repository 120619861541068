<template>
  <v-container>
    <v-textarea
      v-model="textContent"
      :label="params.label"
      clearable
      outlined
      @keyup="setFieldValue(textContent)"
    ></v-textarea>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: ["params", "index", "index_actions", "rules_list"],
  data() {
    return {
      textContent: ""
    };
  },
  mounted() {
    this.textContent = this.rules_list[this.index].actions[
      this.index_actions
    ].fields[this.params.name][this.params.name];
  },
  computed: {
    ...mapState("rules", ["rules"])
  },

  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    setFieldValue(textContent) {
      if (textContent) {
        this.rules_list[this.index].actions[this.index_actions].fields[
          this.params.name
        ][this.params.name] = textContent;
        this.rules_list[this.index].actions[this.index_actions].fields[
          this.params.name
        ].show_component = true;
        this.setRules(this.rules_list);
      }
    }
  }
};
</script>
<style scoped></style>
