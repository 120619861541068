<template>
  <div>
    <v-autocomplete
      chips
      clearable
      deletable-chips
      multiple
      :label="params.label"
      :items="tags"
      @change="setFieldValue(tagValue)"
      @click="updateTagList()"
      v-model="tagValue"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: ["params", "index", "index_actions", "rules_list"],
  data() {
    return {
      tagValue: "",
      tags: []
    };
  },
  mounted() {
    this.tagValue = this.rules_list[this.index].actions[
      this.index_actions
    ].fields[this.params.name][this.params.name];

    this.fields.forEach(e => {
      this.tags.push(e.name);
    });
  },
  computed: {
    ...mapState("rules", ["rules"]),
    ...mapState("solicitationType", ["fields", "new"])
  },
  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    setFieldValue(tagValue) {
      this.rules_list[this.index].actions[this.index_actions].fields[
        this.params.name
      ][this.params.name] = tagValue;
      this.setRules(this.rules_list);
    },
    updateTagList() {
      this.fields.forEach(e => {
        this.tags.push(e.name);
      });
    }
  }
};
</script>
<style scoped></style>
