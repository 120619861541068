<template>
  <div>
    <v-autocomplete
      :items="params.options"
      item-text="text"
      :label="params.label"
      v-model="selectField"
      @change="setFieldValue($event)"
      return-object
      clearable
      outlined
      dense
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: ["params", "index", "index_actions", "rules_list"],
  data() {
    return {
      selectField: ""
    };
  },
  mounted() {
    this.selectField = this.rules_list[this.index].actions[
      this.index_actions
    ].fields[this.params.name][this.params.name];
  },

  computed: {
    ...mapState("rules", ["rules"])
  },

  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    setFieldValue(event) {
      this.rules_list[this.index].actions[this.index_actions].fields[
        this.params.name
      ][this.params.name] = event;
      this.setRules(this.rules_list);
    }
  }
};
</script>
<style scoped></style>
