<template>
  <div>
    <v-switch
      v-if="isClicked"
      @change="setFieldValue($event)"
      v-model="switchValue"
      :label="params.label"
    ></v-switch>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: ["params", "index", "index_actions", "rules_list"],
  data() {
    return {
      switchValue: "",
      isClicked: true
    };
  },
  mounted() {
    this.switchValue = this.rules_list[this.index].actions[
      this.index_actions
    ].fields[this.params.name][this.params.name];
  },
  computed: {
    ...mapState("rules", ["rules"])
  },
  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    setFieldValue(event) {
      this.rules_list[this.index].actions[this.index_actions].fields[
        this.params.name
      ][this.params.name] = event;
      this.setRules(this.rules_list);
      this.$emit("changeShowStatus", {
        component: this.params.name,
        status: this.switchValue
      });
    },

    changeValue() {
      this.isClicked = true;
    }
  }
};
</script>
<style scoped></style>
