<template>
  <div class="px-0">
    <v-row>
      <v-card-title class="subtitle-1 text--primary py-0 pb-3">
        Configurações do Email
      </v-card-title>
      <v-col md="11" cols="12" class="pt-0 pb-0" v-if="solicitationType">
        <v-text-field
          @input="updateTo"
          label="Enviar para"
          v-model="toEmail"
          outlined
          dense
          :placeholder="toEmail ? toEmail : defaultEmail"
          :loading="loading"
          :rules="[validate.email]"
        ></v-text-field>
      </v-col>
      <v-col cols="11" class="py-0" v-if="solicitationType">
        <v-combobox
          multiple
          label="E-mails cópia"
          outlined
          chips
          deletable-chips
          @change="updateCc"
          @focusin="disableButton(true)"
          @focusout="disableButton(false)"
          @keyup="disableButton(true)"
          dense
          small-chips
          clearable
          :rules="[validate.email]"
          v-model="ccEmail"
          autocomplete="off"
          :loading="loading"
        >
        </v-combobox>
      </v-col>
      <v-col md="11" cols="12" class="pt-0 pb-0">
        <v-text-field
          @input="updateSubject"
          label="Assunto"
          v-model="subjectEmail"
          outlined
          dense
          :append-icon="'mdi-tag-plus'"
          :loading="subjectLoading"
          :disabled="subjectLoading"
          @click:append="
            modalStatus ? (modalStatus = false) : (modalStatus = true)
          "
        ></v-text-field>
      </v-col>
      <TagsComponent
        :tagType="'subjectField'"
        :showIcon="false"
        :modalStatus="modalStatus"
        :selectedId="selectedId"
        @addTagEmail="addTagEmail"
        @addTagSubject="addTagSubject"
      />
    </v-row>
    <v-row>
      <v-col md="11" cols="12">
        <quill-editor
          :content="messageEmail"
          :options="editorOption"
          @change="onEditorChange($event)"
        />
      </v-col>
      <TagsComponent
        :tagType="'bodyField'"
        :showIcon="true"
        :selectedId="selectedId"
        @addTagEmail="addTagEmail"
        @addTagSubject="addTagSubject"
      />
    </v-row>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapGetters, mapActions } from "vuex";
import TagsComponent from "../tag/TagsComponent";

export default {
  components: {
    quillEditor,
    TagsComponent
  },
  props: ["index", "toEmail", "subjectEmail", "messageEmail", "selectedId", "solicitationType", "ccEmail", "defaultEmail"],
  data() {
    return {
      subjectLoading: true,
      loading: true,
      tags: [],
      action_email: "",
      action_subject: "",
      action_message: "",
      search_tags: [],
      content: "",
      editorOption: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline"],
            ["link", "image"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        }
      },
      validate: {
        email: val => {
          if (!val) {
            return true;
          }
          let validate = true;
          //Separe os email
          let list = [];
          val = val + "";
          if (val) {
            let split = val.split(",");
            //se for mais de 1 ele verificar todos
            if (typeof split == "object" && split.length > 1) {
              list = split;
            } else {
              list = [val];
            }
          }
          let erros = "";
          for (let i in list) {
            let email = list[i];
            let email_trim = email.trim();
            let regex = new RegExp("^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,})+$");
            if (!regex.test(email_trim)) {
              if (email_trim != "undefined") {
                erros += email_trim + " não é um e-mail válido. ";
              }
            }
          }
          if (erros != "") {
            validate = erros;
          }
          return validate;
        }
      },
      selections: [],
      actionSubject: "",
      emailTitle: "",
      meessageContent: "",
      modalStatus: false
    };
  },
  async mounted() {
    await this.loadTagList({
      id: this.selectedId
    }).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.tags = this.getTagsFormated;
    this.search_tags = this.getTagsFormated;

    this.loading = false;
    this.subjectLoading = false;
  },

  computed: {
    ...mapGetters("rules", ["getRules", "getTags", "getTagsFormated"])
  },

  methods: {
    ...mapActions("rules", ["loadTagList"]),
    onEditorChange(quill) {
      this.meessageContent = quill.html;
      this.messageEmail = quill.html;
      this.$emit("changeMessage", {
        message: quill.html,
        index_rule: this.index
      });
    },

    searchTagsJson(text) {
      if (text != "") {
        let search = new RegExp("(.+)?" + text + "(.+)?", "i");
        let new_tags = [];
        for (let t in this.tags) {
          const tag = this.tags[t];
          if (search.test(tag)) {
            new_tags.push(tag);
          }
        }
        this.search_tags = new_tags;
      } else {
        this.search_tags = this.tags;
      }
    },

    addTagEmail(item) {
      let content = "";
      if (this.messageEmail) {
        content = this.messageEmail;
      }
      this.messageEmail = content + "{{" + item + "}}";
    },

    addTagSubject(item) {
      this.subjectEmail =
        this.subjectEmail != undefined
          ? this.subjectEmail + "{{" + item + "}}"
          : "{{" + item + "}}";

      this.$emit("changeSubject", {
        title: this.subjectEmail,
        index_rule: this.index
      });
    },

    updateSubject(val) {
      this.subjectEmail = val;
      this.$emit("changeSubject", {
        title: this.subjectEmail,
        index_rule: this.index
      });
    },

    updateTo(val) {
      this.toEmail = val;
      this.$emit("changeTo", {
        to: this.toEmail,
        index_rule: this.index
      });
    },

    updateCc(val) {
      this.$emit("changeCc", {
        cc: val,
        index_rule: this.index
      });
    },

    disableButton(value) {
      this.$emit("disableButton", {
        value: value,
        index_rule: this.index
      });
    }
  }
};
</script>
