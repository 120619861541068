<template>
  <div>
    <v-autocomplete
      v-model="search"
      :items="users.data"
      item-text="nome"
      outlined
      dense
      clearable
      :label="label"
      return-object
      @change="saveSelected"
      autofocus
      :loading="loading"
      :disabled="loading"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      search: {},
      loading: true
    };
  },
  async mounted() {
    await this.loadUsers().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.loading = false;
  },
  computed: {
    ...mapState("user", ["users"]),
    ...mapGetters({
      label: "forms/getItemLabel",
      text: "forms/getItemText"
    })
  },
  methods: {
    ...mapActions("user", ["loadUsers"]),
    ...mapMutations({
      setModel: "forms/setModelRequester"
    }),
    saveSelected() {
      this.setModel({
        id: this.search.id_join,
        usuario: this.search.nome
      });
    }
  }
};
</script>
<style scoped></style>
