<template>
  <div>
    <label>{{ label }}</label>
    <v-text-field v-model="text" label=""></v-text-field>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title>
          <h4>Pesquise o pessoa</h4>
        </v-card-title>

        <v-card-text>
          <v-text-field
            :loading="search_loading"
            v-model="search"
            :append-icon="'mdi-magnify'"
            :clear-icon="'mdi-close-circle'"
            clearable
            @click:append="searchPerson()"
            @click:clear="clearSearch()"
            @change="enterSearch()"
          />

          <v-alert dense outlined type="error" v-if="search_error">{{
            search_error
          }}</v-alert>

          <v-list-item-group color="primary">
            <v-list-item v-for="(search_item, i) in list_response" :key="i">
              <v-list-item-content>
                <v-list-item-title
                  v-text="search_item.name"
                  @click="select(search_item)"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="gray" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="green" text @click="saveSelected()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../../axios.config";
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      search_loading: false,
      search_error: null,
      list_response: [],
      search: "",
      selected: {}
    };
  },
  computed: {
    ...mapGetters({
      name: "forms/getItemName",
      label: "forms/getItemLabel",
      text: "forms/getItemText",
      value: "forms/getItemValue"
    })
  },
  methods: {
    ...mapMutations({
      setModel: "forms/setModel"
    }),
    clearSearch() {
      this.search = "";
    },
    enterSearch() {
      if (event.keyCode == 13) {
        this.searchPerson();
      }
    },
    searchPerson() {
      this.search_loading = true;
      this.search_error = null;
      this.list_response = "";
      let context = this;
      axios
        .get("fields/emei", { search: this.search })
        .then(function(response) {
          if (response.data.error) {
            context.search_error = response.data.message;
          } else if (response.data.length) {
            context.list_response = response.data;
          } else {
            context.search_error = "Nenhum resultado encontrado";
          }
          context.search_loading = false;
        })
        .catch(function() {
          context.search_error = "Erro ao pesquisar";
          context.search_loading = false;
        });
    },
    select(item) {
      this.selected = item;
    },
    saveSelected() {
      this.setModel(this.selected);
      this.dialog = false;
    }
  },
  created() {}
};
</script>
<style scoped>
.v-list-item__title {
  word-break: break-word;
  white-space: normal;
}
</style>
