<template>
  <div>
    <v-text-field
      v-model="textValue"
      :label="params.label"
      outlined
      dense
      :placeholder="params.label"
      @keyup="setFieldValue(textValue)"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: ["params", "index", "index_actions", "rules_list"],
  data() {
    return {
      textValue: ""
    };
  },

  mounted() {
    this.textValue = this.rules_list[this.index].actions[
      this.index_actions
    ].fields[this.params.name][this.params.name];
  },

  computed: {
    ...mapState("rules", ["rules"])
  },

  methods: {
    ...mapMutations({ setRules: "rules/setRules" }),

    setFieldValue(textValue) {
      this.rules_list[this.index].actions[this.index_actions].fields[
        this.params.name
      ][this.params.name] = textValue;
      this.setRules(this.rules_list);
    }
  }
};
</script>
<style scoped></style>
